//import from utils 
import { linspace, initalise } from "../plot_utils.js"

//This file contains functions which filter through the API results to grab the correct 
//pieces of information for each graph 


export function getBlockIDs(data){
    return Object.keys(data['JobResults']);
}

export function getDiversityStepData(data, pc, blockID) {

    if (blockID == undefined){
        var blockData = data['JobResults']['diversity_step_graph']['DiversityAgainstStepGraph'][pc]
        var stages = data['JobResults']['diversity_step_graph']['StageLabels']
    }
    else {
        var blockData = data['JobResults'][blockID]['DiversityAgainstStepGraph'][pc]
        var stages = data['JobResults'][blockID]['StageLabels']
    }

    var returnData = {'x':stages, 'xInitials':initalise(stages), 'y':blockData.y, 
    'yerr':blockData.y_err, 'benchmark':blockData.benchmark,
    'saText':blockData.SmartAssistant}

    return returnData
}
export function getDiversityStepComparisonData(data, pc) {

    var returnData = {};

    let blockIDs = getBlockIDs(data);

    returnData['Before'] = getDiversityStepData(data, pc, blockIDs[0])
    returnData['After'] = getDiversityStepData(data, pc, blockIDs[1])
    
    return returnData;
}
export function getBayesTriangleThresholdsData(data, pc, blockID) {
    
    var returnData = {}
    var thresholds;

    if (blockID == undefined){
        var blockData = data['JobResults']['success_chance_and_bayes_graph']['EvidenceTriangleGraph'][pc]; //bayes triangle data for ALL steps 
        var stages = data['JobResults']['success_chance_and_bayes_graph']['StageLabels']
    }
    else{
        var blockData = data['JobResults'][blockID]['EvidenceTriangleGraph'][pc]; //bayes triangle data for ALL steps 
        var stages = data['JobResults'][blockID]['StageLabels']
    }


    for (let stageID in blockData) {
        if (!thresholds){
            thresholds = blockData[stageID]['Thresholds']
        }
        if(pc =='Ages'){ // dont initialise the ages labels
            var xLabels = blockData[stageID].Groups.map(item => {
                if (item.includes('-')) {
                  const [startNum] = item.split('-');
                  return startNum + '+';
                } 
                if (item.includes('+')) {
                    return item
                }
                else {
                  return item.substring(0, 1);
                }
              });
        }
        else xLabels =  initalise(blockData[stageID].Groups)

        returnData[stages[parseInt(stageID)]] = {'yLabels':blockData[stageID].Groups, 'xLabels':xLabels, 
                            'Z':blockData[stageID].ThresholdedEvidenceForBias, 'saText':blockData[stageID].SmartAssistant}
    }
    return [returnData, thresholds]
}
export function getBayesTriangleComparisonData(data, pc) {

    let blockIDs = getBlockIDs(data);

    var [bayesTriangleDataBefore, thresholdsBefore] = getBayesTriangleThresholdsData(data, pc, blockIDs[0]);
    var [bayesTriangleDataAfter, thresholdsAfter] = getBayesTriangleThresholdsData(data, pc, blockIDs[1]);
    return [{'Before':bayesTriangleDataBefore, 'After':bayesTriangleDataAfter}, {'Before':thresholdsBefore, 'After':thresholdsAfter}]
}
export function getQuantityofBiasData(data) {
    var returnData = {};
    var x_jitter = linspace(-0.25, 0.25, Object.keys(data['JobResults']['global_bias_graph']['CategoriesData']['Categories']).length);
    var num_stages = Object.keys(data['JobResults']['global_bias_graph']['AllStagesAndCatsBiasSpreadGraph']).length
    var stageLabels = data['JobResults']['global_bias_graph']['StageLabels'];

    var saTextRaw = data['JobResults']['global_bias_graph']["AllStagesAndCatsBiasSpreadGraph_SmartAssistant"];
    var saText = []

    if (saTextRaw.length === 0) {
        saText = [`Although the imbalances detected are not significant enough to assert that any of the hiring stages are completely imbalanced, 
        it is possible that imbalances between different groups still persist. To explore these potential imbalances, please proceed by clicking on the stages below.`]
    }
    else{

        for (let biasExample = 0; biasExample < saTextRaw.length; biasExample++) {
            const saBiasData = saTextRaw[biasExample];

            var saString = `Evidence for  ${saBiasData['Category']} imbalances at ${saBiasData['StepNiceName']}.`;
            saText.push(saString)
        }
    }

    // Object.keys(data['JobResults']['DiversityAgainstStepGraph']).forEach((pc, jj) => {
    for (let [jj, pc_dict] of data['JobResults']['global_bias_graph']['CategoriesData']['Categories'].entries()) {
        let pc = pc_dict['ID']
        var xs = new Array(num_stages).fill(0);
        var ys = new Array(num_stages).fill(0);
        var yerrs = new Array(2).fill().map(() => new Array(num_stages).fill(0));
        var alphas = new Array(num_stages).fill(1);
        var base = new Array(num_stages).fill(0);
        var tops = new Array(num_stages).fill(0);

        for (let ii = 0; ii <num_stages; ii++) {
            var pcStageData = data['JobResults']['global_bias_graph']['AllStagesAndCatsBiasSpreadGraph'][ii.toString()][pc];
            xs[ii] = ii;
            ys[ii] = pcStageData['E[sigma]'] * 100;
            yerrs[0][ii] = Math.abs(pcStageData['HDI[sigma]'][0] - ys[ii] / 100) * 100;
            yerrs[1][ii] = Math.abs(pcStageData['HDI[sigma]'][1] - ys[ii] / 100) * 100;

            if (yerrs[0][ii] + yerrs[1][ii] > 10) {
                alphas[ii] = 0.1;
            }
        }
        
        xs.forEach((_, kk) => xs[kk] += x_jitter[jj]);
        
        tops.forEach((_, kk) => tops[kk] = ys[kk] + yerrs[1][kk]);
        
        base.forEach((_, kk) => base[kk] = ys[kk] - yerrs[0][kk]);
        
        returnData[pc] = {'x':xs, 'y':tops, 'width':x_jitter[1]-x_jitter[0], 'base':base, 'scatter':ys, 'alphas':alphas};
    
    };

    return [returnData, stageLabels, saText];
}
export function getStackedBarData(data, pc, blockID) {

    var returnData = []
    if (blockID == undefined){
        var blockDataPC = data['JobResults']['diversity_step_graph']['DiversityAgainstStepGraph'][pc]['RawNumbers']
        var groupsPC = data['JobResults']['diversity_step_graph'][pc]['Groups']
        var stages = data['JobResults']['diversity_step_graph']['StageLabels']
    }
    else{
        var blockDataPC = data['JobResults'][blockID]['DiversityAgainstStepGraph'][pc]['RawNumbers']
        var groupsPC = data['JobResults'][blockID][pc]['Groups']
        var stages = data['JobResults'][blockID]['StageLabels']
    }
   
    // loop over groups within the pc
    for (let ii = 0; ii < groupsPC.length; ii++) {
        let countForGroup = [];
        let rawCountForGroup = [];
        // loop over stages
        for (let stage in blockDataPC) {
            var sum = blockDataPC[stage]['NumberOfEachGroup'].reduce((partialSum, a) => partialSum + a, 0);
            countForGroup.push((blockDataPC[stage]['NumberOfEachGroup'][ii] / sum) * 100);
            rawCountForGroup.push(blockDataPC[stage]['NumberOfEachGroup'][ii])
        }
        returnData.push({ 'group': groupsPC[ii], 'counts': countForGroup, 'raw_counts': rawCountForGroup });
    }   

    return [returnData, stages]
}
export function getStackedBarComparisonData(data, pc){

    var returnData = {}

    let blockIDs = getBlockIDs(data);

    returnData['Before'] = getStackedBarData(data, pc, blockIDs[0])
    returnData['After'] = getStackedBarData(data, pc, blockIDs[1])
    
    return returnData;
}
export function getBulletChartData(data, pc, blockID) {
    
    var returnData = {}
    var returnSAText = {}

    if (blockID == undefined){
        var blockData = data['JobResults']['success_chance_and_bayes_graph']['SuccessChanceGraph'][pc]; //success chance data for PC at all steps
        var stages = data['JobResults']['success_chance_and_bayes_graph']['StageLabels'];
    }
    else{
        var blockData = data['JobResults'][blockID]['SuccessChanceGraph'][pc]; //success chance data for PC at all steps
        var stages = data['JobResults'][blockID]['StageLabels'];
    }

    for (let stageID in blockData) {
        returnData[stages[stageID]] = {}
        for (let group in blockData[stageID])
            if (group != 'SmartAssistant'){
                
                returnData[stages[stageID]][group] = {'E':blockData[stageID][group]['E[p]']*100, 'STD':blockData[stageID][group]['STD[p]']*100};
            }
        returnSAText[stages[stageID]] = blockData[stageID].SmartAssistant;
    }
    return [returnData, returnSAText]
}
export function getBulletChartComparisonData(data, pc){

    let blockIDs = getBlockIDs(data);


    var [bulletChartDataBefore, saTextBefore] = getBulletChartData(data, pc, blockIDs[0])
    var [bulletChartDataAfter, saTextAfter] = getBulletChartData(data, pc, blockIDs[1])


    return [{'Before':bulletChartDataBefore, 'After':bulletChartDataAfter}, {'Before':saTextBefore, 'After':saTextAfter}]

}

//----------------------------------------------- not in use -----------------------------------------------//

export function getPieChartData(data) {

    var returnData = {}
    var pieData = data['TotalCounts']
    for (let pc in pieData) {
        var counts = pieData[pc].Counts;
        var labels = []
        for (let group in pieData[pc].Labels){
          
            labels.push(pieData[pc].Labels[group])
        }
            returnData[pc] = {'counts':counts, 'labels':labels}
    }
    
    //grouping of categories
    var maxGroups = 5
    for (let pc in returnData) {
        if(returnData[pc].counts.length>maxGroups){

              const counts = returnData[pc].counts;
              const labels = returnData[pc].labels;
              //sort the list in ascending order
              var sortedCounts = counts.slice().sort((a, b) => b - a);
              var sortedLabels = sortedCounts.map(num => labels[counts.indexOf(num)]);
            
              //take first maxGroups- rest will be merged into other 
              var groupedCounts = sortedCounts.slice(0,  maxGroups-1);
              var groupedLabels = sortedLabels.slice(0,  maxGroups-1);

              var mergedCount = 0;
              for (let index =  maxGroups; index < sortedCounts.length; index++) {
                  mergedCount+= sortedCounts[index];
              }
            
              groupedCounts.push(mergedCount)
              groupedLabels.push('Other')
            

              returnData[pc]['counts'] = groupedCounts;
              returnData[pc]['labels'] = groupedLabels;
  
          }
    }
    
    return returnData
}  
export function getTableData(data, blockID) {
}
export function getSuccessChanceData(data, pc) {
    
    var returnData = {}
    var returnSAText = {}
    var blockData = data['JobResults']['success_chance_and_bayes_graph']['SuccessChanceGraph'][pc]; //success chance data for PC at all steps
    var stages = data['JobResults']['success_chance_and_bayes_graph']['StageLabels'];
    for (let stageID in blockData) {
        returnData[stages[stageID]] = {}
        for (let group in blockData[stageID])
            if (group != 'SmartAssistant'){
                
                var x = blockData[stageID][group].SuccessChance;
                x = x.map(x => x * 100);
                var y = blockData[stageID][group].PosteriorDensity;
                y[0] = 0;
                y[y.length - 1] = 0;
                
                returnData[stages[stageID]][group] = {'x':x, 'y':y,
                'E':blockData[stageID][group]['E[p]']*100, 'STD':blockData[stageID][group]['STD[p]']*100};
            }
        returnSAText[stages[stageID]] = blockData[stageID].SmartAssistant;
    }
    return [returnData, returnSAText]
}
export function getSuccessChanceComparisonData(data, pc) {

    let blockIDs = getBlockIDs(data);

    var [successChanceDataBefore, saTextBefore] = getSuccessChanceData(data, blockIDs[0], pc)
    var [successChanceDataAfter, saTextAfter] = getSuccessChanceData(data, blockIDs[1], pc)
    return [{'Before':successChanceDataBefore, 'After':successChanceDataAfter}, {'Before':saTextBefore, 'After':saTextAfter}]
}
export function getBayesTriangleData(data, blockID, pc) {
    
    var returnData = {}
    var blockData = data['JobResults']['success_chance_and_bayes_graph']['EvidenceTriangleGraph'][pc]; //bayes triangle data for ALL steps 
    var stages = data['JobResults']['success_chance_and_bayes_graph']['StageLabels']

    for (let stageID in blockData) {
        if(pc!='Ages'){ // dont initialise the ages labels
            var xLabels = initalise(blockData[stageID].Groups)
        }
        else xLabels = blockData[stageID].Groups

        returnData[stages[parseInt(stageID)]] = {'yLabels':blockData[stageID].Groups, 'xLabels':xLabels, 
                            'Z':blockData[stageID].EvidenceForBias, 'saText':blockData[stageID].SmartAssistant}
    }
    return returnData
}
export function getTimeSeriesData(data, blockID, pc, stageLabels){

    //TO DO: currently takes stage labels from the main figure request - will change when we have individual figure results
    var returnData = {}

    const blockDataTimeSeries = data[blockID][pc];

    for (let stage_id in blockDataTimeSeries){
      
        var stage =  blockDataTimeSeries[stage_id]
        
        var x = stage['Labels']
        //x = stage['MonthsFromRef']
    
        var y = stage['PredictedNeff_mu']
        //y = stage['Neff']
        
        var yerr = stage['PredictedNeff_sigma']
        //yerr = stage['Neff_err']
        
        var yraw = stage['Neff']

        returnData[stageLabels[parseInt(stage_id)]] = {'x':x, 'y':y, 'yraw':yraw, 'yerr':yerr}
    }
    
    
    return [returnData, stageLabels]
}
export function getTimeSeriesComparisonData(data, pc, stageLabels){

    var returnData = {}

    let blockIDs = getBlockIDs(data);

    returnData['Before'] = getTimeSeriesData(data, blockIDs[0], pc, stageLabels)
    returnData['After'] = getTimeSeriesData(data, blockIDs[1], pc, stageLabels)
    
    return returnData;
}