// NB: how to limit it to number of characters:
// p.textContent = `${restaurant.description.substring(0, 240)}` // Limit to 240 chars

export function stopLoader(){
    let spinner = document.getElementsByClassName("spinner")[0];
    spinner.setAttribute("class", "spinner");
    let loader_wrapper = document.getElementById("loader-wrapper");
    loader_wrapper.style.display = "none";
}

export function closeModal() {
    // close the 'delete dataset' pop-up
    let modal = document.getElementById('popup-modal');
    modal.style.transform = 'scale3d(0.8, 0.8, 1)';
    modal.style.opacity = "0.0";
    let modal_wrapper = document.getElementById('modal-wrapper')
    modal_wrapper.style.opacity = "0.0";
    modal_wrapper.style.display = 'none';
}
export function openModal() {
    // open the 'delete dataset' pop-up
    let modal_wrapper = document.getElementById('modal-wrapper')
    modal_wrapper.style.display = 'flex';
    modal_wrapper.style.opacity = "1.0";
    let modal = document.getElementById('popup-modal');
    modal.style.opacity = "1.0";
    modal.style.transform = 'scale3d(1, 1, 1)';
}

export function setUserInitials(userInitials){
    let userInitialsClass = document.getElementsByClassName('account-name');
    userInitialsClass[0].textContent = userInitials;
}

function parseUserInitials(handoverToken) {
    let userInitials = "";
    if (typeof handoverToken.name === "string") {
        // Remove double spaces, and spaces at the start or end.
        const cleanName = handoverToken.name.replace(/  +/g, ' ').replace(/(^ )|( $)/g, '');
        const names = cleanName.split(' ');
        // Map each name to its upper-case first letter and join them to form the initials.
        userInitials = names.filter(name => name).map(name => name[0].toUpperCase()).join('');
    }
    if (!userInitials && typeof handoverToken.emails === 'object' && handoverToken.emails.length) {
        const initialsFromEmail = email => email
            .split('@')[0]
            .split('.')
            .filter(part => part)
            .map(part => part[0].toUpperCase())
            .join('');
        const potentialInitials = handoverToken.emails.map(initialsFromEmail);
        // Try and find initials of length 2, then 1, then choose anything.
        userInitials = potentialInitials.filter(initial => initial.length === 2)[0]
            || potentialInitials.filter(initial => initial.length === 1)[0]
            || potentialInitials.filter(initial => initial.length)[0]
            || "";
    }
    let shortUserInitials = userInitials;
    if (shortUserInitials.length > 2) {
        shortUserInitials = userInitials[0] + userInitials[userInitials.length-1];
    }
    return { userInitials, shortUserInitials };
}

export async function initToken() {
    // get token ID from url
    const argumentDict = (function(a) {
        if (a == "") return {};
        var b = {};
        for (var i = 0; i < a.length; ++i)
        {
            var p=a[i].split('=', 2);
            if (p.length == 1)
                b[p[0]] = "";
            else
                b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
      })(window.location.search.substr(1).split('&'));

    // check if we have a tokenId
    if (argumentDict.hasOwnProperty('tokenId')) {
        const tokenId = argumentDict['tokenId'];
        const handoverToken = await getParsedHandoverToken(tokenId);

        // add authentication to sessionstorage
        sessionStorage.setItem('userToken', handoverToken.userToken);
        sessionStorage.setItem('serviceToken', handoverToken.serviceToken)
        sessionStorage.setItem('DomainId', handoverToken.domainId);
        sessionStorage.setItem('IntegrationSettingsId', handoverToken.integrationSettingsId);
        sessionStorage.setItem('OrganisationName', handoverToken.organisationName);
        const { userInitials, shortUserInitials } = parseUserInitials(handoverToken);
        sessionStorage.setItem('UserInitials', userInitials);
        sessionStorage.setItem('ShortUserInitials', shortUserInitials);
    } else {
        console.log('No tokenId in url');
        // redirect to accounts login page if we don't already have the session storage items set
    }
    return;
}

function parseUserInitialsTests() {
    console.assert(parseUserInitials({}).shortUserInitials === "");
    console.assert(parseUserInitials({
        name: "",
    }).shortUserInitials === "");
    console.assert(parseUserInitials({
        emails: [],
    }).shortUserInitials === "");
    console.assert(parseUserInitials({
        name: "",
        emails: [],
    }).shortUserInitials === "");
    console.assert(parseUserInitials({
        name: "",
        emails: [""],
    }).shortUserInitials === "");
    console.assert(parseUserInitials({
        name: "",
        emails: ["", ""],
    }).shortUserInitials === "");
    console.assert(parseUserInitials({
        name: "Jacob Peter O'Toole",
    }).userInitials === "JPO");
    console.assert(parseUserInitials({
        name: "Jacob Peter O'Toole",
    }).shortUserInitials === "JO");
    console.assert(parseUserInitials({
        name: "jacob otoole",
    }).shortUserInitials === "JO");
    console.assert(parseUserInitials({
        name: "jacob",
    }).shortUserInitials === "J");
    console.assert(parseUserInitials({
        emails: ["jacob"],
    }).userInitials === "J");
    console.assert(parseUserInitials({
        emails: ["jacob"],
    }).shortUserInitials === "J");
    console.assert(parseUserInitials({
        emails: ["jacob@mevitae.com"],
    }).userInitials === "J");
    console.assert(parseUserInitials({
        emails: ["jacob.otoole@mevitae.com"],
    }).userInitials === "JO");
    console.assert(parseUserInitials({
        emails: ["jacob.p.otoole@mevitae.com"],
    }).userInitials === "JPO");
    console.assert(parseUserInitials({
        emails: ["jacob..otoole@mevitae.com"],
    }).userInitials === "JO");
    console.assert(parseUserInitials({
        emails: ["jacob..otoole.@mevitae.com"],
    }).userInitials === "JO");
    console.assert(parseUserInitials({
        emails: [".jacob..otoole..@mevitae.com"],
    }).userInitials === "JO");
    console.assert(parseUserInitials({
        emails: [".jacob..peter.otoole..@mevitae.com"],
    }).userInitials === "JPO");
    console.assert(parseUserInitials({
        emails: [".jacob..peter.otoole..@mevitae.com"],
    }).shortUserInitials === "JO");
    console.assert(parseUserInitials({
        emails: ["vivek.d@mevitae.com"],
    }).shortUserInitials === "VD");
}


export function setWelcomeMessage(organisationName){
    let welcomeMessageElement = document.getElementById('page-header');
    welcomeMessageElement.textContent = `Welcome, ${organisationName}!`
}

export function setLogOutButton(){
    let logoutButton = document.getElementById("logout-button");
    logoutButton.addEventListener('click', function() {
        sessionStorage.clear();
    })
}
