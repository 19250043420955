import { getDiversityStepComparisonData, getStackedBarComparisonData } from "../../data-requests/data-json-search.js";
import { genColorScale, findNeighbours, formatTickLabels, legendYLocationFromTickLines } from "../../plot_utils.js";
import { mevitae_symbol_before, mevitae_symbol_after, mevitae_purple, openSans_light } from "../../constants.js";
import { add, sum } from "../../calculation_utils.js"

//constants and utils//
import { PLOTLY_CONFIG, pc_cmap, openSans_semiBold, mevitae_grey } from "../../constants.js";
import { yRangeDS } from "../../plot_utils.js";
const legendSpacing = '      ';
var colourNames = Object.keys(pc_cmap);

function getCountForGroup(group_dicts_for_pc, group) {
  for (let group_dict of group_dicts_for_pc) {
    if (group_dict['group'] === group) {
      return group_dict['counts']
    }
  }
}

function convertToPercentages(fractions) {
  let counts_sum = sum(fractions);
  return fractions.map(fraction => fraction/counts_sum * 100);
}


export function generateBarChartTraceLayout(composition_data, pc, organisation_colour=mevitae_purple.rgba, industry_colour=mevitae_grey.rgba(1)){

    var schemeColour = pc_cmap[pc].rgba

    // get all data
    let groups = [];
    let counts_org = [];
    let percentages_org = [];
    let percentages_benchmark = [];
    for (let group_dict of composition_data['raw_numbers'][pc]) {
        groups.push(group_dict['group'])
        counts_org.push(group_dict['counts'])
        percentages_org.push(group_dict['counts'] / composition_data['raw_numbers_total'][pc][0] * 100)
        percentages_benchmark.push(getCountForGroup(composition_data['benchmark_dicts'][pc], group_dict['group']))
    }
    // make sure counts always add up to 100%
    percentages_org = convertToPercentages(percentages_org);
    percentages_benchmark = convertToPercentages(percentages_benchmark);

    var xValues = [];
    for (let ii = 0; ii < groups.length; ii++) {
      xValues.push(ii+1)
    }

    var offset = 0.12;
    var tickvals = []
    for (let val in xValues){
      val = parseFloat(val);
      tickvals.push(...[val + 1 - offset, val + 1 + offset])
    }
    var ticktext = []
      for (var i = 0; i < groups.length; i++) {
      //ticktext.push(...['You', 'Benchmark']);
    }
    var annotations = xValues.map(function(x_val, index) {
      return {
        x: x_val,
        y: -0.2,
        text: groups[index],
        showarrow: false,
        font: {
          size: 12
        },
        yref: 'paper'
      };
    });

    // var plotRangeBefore = yRangeDS(diversityStepComparisonData.Before);
    // var plotRangeAfter = yRangeDS(diversityStepComparisonData.After);
    // var plotRange = [Math.min(plotRangeBefore[0], plotRangeAfter[0]), Math.max(plotRangeBefore[1], plotRangeAfter[1])]
 
    const formatted_xTicks = formatTickLabels(groups);
    var layout = {
        dragmode:false,
        showlegend:true,
        autosize:true,
        legend: {
            x: 0.5,
            xanchor: 'center',
            y: legendYLocationFromTickLines(formatted_xTicks),
            bgcolor: 'rgba(0,0,0,0)',
            font:{size:12},
            orientation:"h",
        },
        margin:{
            t:0,
            b:50,
            l:30,
            r:0,
            pad:5
        },
        barmode:'stack', 
        font: openSans_light,
        xaxis: {
            showgrid: false,
            zeroline: false,
            showline : false,
            ticktext : formatted_xTicks,
            tickmode : 'array',
            tickvals : xValues, //diversityStepData.x,
            range: [0.5, groups.length + 0.5],
            //showticklabels: false
        },
        yaxis: {dtick:10, ticksuffix:"%", tickformat:'.0f', zeroline:false}, //domain: [0, 1.0], range: [0, 100] 
        shapes: [{
            type: 'line',
            xref: 'paper',
            yref: 'paper',
            x0: 0,
            x1: 1,
            y0: 0.0,
            y1: 0.0,
            line:{color : mevitae_grey.rgba(1), width:0.5, dash:'solid'}
            }],
        //annotations:annotations
    }

    //diversity step trace 
    //Create traces (plots)//
    // var diversityStepBeforeTrace = {x:xValues, y:diversityStepComparisonData.Before.y, name: 'Diversity Before '+legendSpacing, mode:'markers', error_y: {type: 'data',
    // array: diversityStepComparisonData.Before.yerr, color:schemeColour["0.3"], visible: true}, marker:{color:schemeColour["0.1"], symbol:mevitae_symbol_before, size:12, line: {width:1, color:schemeColour["0.5"]}},
    // text : diversityStepComparisonData.Before.x, hovermode:'closest', hovertemplate : "%{text}<br>Before:<br>%{y} <extra></extra>",  yaxis:'y2'};
    
    // var diversityStepAfterTrace = {x:xValues, y:diversityStepComparisonData.After.y, name: 'Diversity After '+legendSpacing, mode:'markers', error_y: {type: 'data',
    // array: diversityStepComparisonData.After.yerr, color:schemeColour["0.8"], visible: true}, marker:{color:schemeColour["0.6"], symbol:mevitae_symbol_after, size:12, line: {width:1, color:schemeColour["1"]}},
    // text : diversityStepComparisonData.After.x, hovermode:'closest', hovertemplate : "%{text}<br>After:<br>%{y} <extra></extra>", yaxis:'y2'};

    // //take only the previous benchmark
    // if(diversityStepComparisonData.Before.benchmark){
    //     var benchmarkTrace = {x:diversityStepComparisonData.Before.x, y:Array(diversityStepComparisonData.Before.x.length).fill(diversityStepComparisonData.Before.benchmark), name: 'Diversity Benchmark'+legendSpacing, 
    //                         mode:'lines', type: 'scatter', line: {color: schemeColour["0.8"], width: 2, dash:'dashdot'}, hovertemplate : "Benchmark: %{y} <extra></extra>",  yaxis:'y2'};
    
    //     var traceList = [diversityStepBeforeTrace, diversityStepAfterTrace, benchmarkTrace]; 
    // }
    // else traceList = [diversityStepBeforeTrace, diversityStepAfterTrace];

    // var saTextDSC = {'Before':diversityStepComparisonData.Before.saText, 'After':diversityStepComparisonData.After.saText}   

    var [neighbourPC1, neighbourPC2] = findNeighbours(pc_cmap, pc);
    var secondColour = pc_cmap[neighbourPC1].rgba["0.8"];
    var thirdColour = pc_cmap[neighbourPC2].rgba["0.8"];
    // var colourList = [schemeColour["0.8"], secondColour, thirdColour]

    let traceList = [];
    // sum over before/after: replace with organisation/industry
    for (let data_type of ['organisation', 'industry']){

      // var stackedBarColourMap = [];
      // for (let ii = 0; ii < colourList.length - 1; ii++) {
      //     var colourMap = genColorScale(colourList[ii], colourList[ii+1], Math.ceil(3 / colourList.length) + 1)
      //     stackedBarColourMap.push(...colourMap)
      // }
      

      var legendDisplay;
      var xValuesAdjusted = [];
      let ydata = [];
      let hovertemplate = ""
      var legend_name = 'you';
      if (data_type === 'organisation'){
        xValuesAdjusted = xValues.map(ii => ii - offset);
        legendDisplay = false;
        ydata = percentages_org;
        barcolour = organisation_colour;
        hovertemplate = "%{y:.2f}%<br>" + "(%{meta} hires)" + "<extra></extra>"
      } 
      else if (data_type === 'industry'){
        xValuesAdjusted = xValues.map(ii => ii + offset);
        legendDisplay = true;
        ydata = percentages_benchmark;
        barcolour = industry_colour;
        hovertemplate = "%{y:.2f}%" + "<extra></extra>"
        legend_name = 'benchmark';
      }

      traceList.push({
          x: xValuesAdjusted, //Steps,
          y: ydata,
          name: legend_name,
          width: 0.2,
          // meta: [groupData.group],
          meta: counts_org,
          type: 'bar',
          yaxis: 'y',
          //hovertemplate: '%{meta[0]} %{x}<br>%{y:.2f}% <extra></extra>',
          hovertemplate: hovertemplate,
          marker: {
            color: barcolour,
          }
      })

    }

    return [traceList, layout]

}

export function plotCompositionBar(composition_data, pc, bar_plot_div, organisation_colour=mevitae_purple.rgba, industry_colour=mevitae_grey.rgba(1)) {
  //plot DS and Stacked Bar
  const config = {
    //modeBarButtonsToRemove : PLOTLY_CONFIG.MODEBAR_REMOVE,
    //displaylogo : false
    displayModeBar: false
  };
  [traceList, layout] = generateBarChartTraceLayout(composition_data, pc, organisation_colour=organisation_colour)
  Plotly.react(bar_plot_div, traceList, layout, config);
}