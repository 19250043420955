import { getBulletChartData} from "../../data-requests/data-json-search.js";
import { splitIntoChunks } from "../../plot_utils.js"; 

// plan:
// get data, and then calculate what the max percentage difference is
// then we set the bar width to have 100% + max_difference% + 10%. that's our xlim.
// the benchmark should then be at 100%
// and the pc bars come to 100+difference %, with an stdev simply the number in the dictionary
// we first do this checking ALL data, then we go ahead and make bullet charts for low and high diversity plots separately.


//constants//
import { mevitae_grey, pc_cmap, progress_bar_colours } from "../../constants.js";
const legendSpacing = '       ';
const bulletChartRightMargin = 10  // 10% margin on the right

/**
 * Output the 
 * @param message the message that will be printed
 */
export function calculateDiversityXlim(output_diversity_levels) {
    var max_difference = 0;

    for (let pc_dict of output_diversity_levels['high_diversity']) {
      let local_max_difference = (pc_dict['diff']+pc_dict['err']);
      if (local_max_difference > max_difference) {
        max_difference = local_max_difference;
      }
    }

    return (100 + max_difference + bulletChartRightMargin)
}


/**
 * function that generates the bullet chart traces for either the low_diversity data or the high_diversity data
 * @param {*} diversity_levels_data in the format [{'pc': 'Ethnicity', 'diff': 20, 'err': 3, 'sa': 'above_benchmark'}, 
 * {'pc': 'Age', 'diff': 5, 'err': 2, 'sa': 'above_benchmark'}]
 * @param diveristyXlim max X-value of the bullet chart, output of calculateDiversityXLim
 * @returns 
 */
export function generateDiversityLevelsTraceLayout(diversity_levels_data, diversityXlim){

  // just pick a random colour scheme
  var schemeColour = pc_cmap["Ethnicity"].rgba

  //Pre-defined style Layout//
  var layout = {dragmode : false,
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    // autosize: true,
    autosize: false,
    height: 30,
    legend: {
        x: 0.5,
        xanchor: 'center',
        y: -0.1,
        bgcolor: 'rgba(0,0,0,0)',
        font:{size:12},
        orientation:"h",
    },
    margin : {
        l:150,
        t:0,
        b:0,
        r:150,
        p:2
    },
    xaxis: {
        showgrid: false,
        zeroline: false,
        showline : false,
        ticksuffix : "%",
    },
    yaxis: {
        showgrid: true,
        zeroline: false,
        showline : false,
        showticklabels: false,
        autorange: false, 
        automargin: false},
    textinfo: 'none',
    textposition: 'none',
    // font: {
    //     family: 'Open-Sans-SB',
    //     color:'rgba(160,160,160,1)'},
    // shapes : [{
    //   type: 'line',
    //   xref: 'paper',
    //   yref: 'paper',
    //   x0: 0,
    //   x1: 1,
    //   y0: 0,
    //   y1: 0,
    //   line:{color : 'rgba(160,160,160,1)', width:0.5, dash:'solid'}
    // },
    // {
    //   type: 'line',
    //   xref: 'paper',
    //   yref: 'paper',
    //   x0: 0,
    //   x1: 1,
    //   y0: 1,
    //   y1: 1,
    //   line:{color : 'rgba(160,160,160,1)', width:0.5, dash:'solid'}
    // }],
    // scene : {
    //   aspectratio: {'x':0.3, 'y':1.0, 'z':1}
    // }
  };

  //add vertical gridlines
  // const xGridlines = Array.from({ length: Math.floor(0.75 / 0.15) + 1 }, (_, i) => i * 0.15);

  // for (let ii = 0; ii < xGridlines.length; ii++) {

  //   layout.shapes.push({type: 'line',
  //   xref: 'paper',
  //   yref: 'paper',
  //   x0: xGridlines[ii],
  //   x1: xGridlines[ii],
  //   y0: 0,
  //   y1: 1,
  //   line:{color : 'rgba(160,160,160,1)', width:0.5, dash:'dashdot'}});
  
  // };

  // loop through data dictionaries and add plot trace to each dictionary
  var diversity_levels_withTrace = [];
  for (var pc_dict of diversity_levels_data) {

    traceName = pc_dict['pc'];

    // choose colour for main bar based on distance from benchmark
    // used to be the dark purple 'rgba(45, 17, 69, 1)' for all 
    var bar_colour = progress_bar_colours.rgb[0];
    if (pc_dict['diff'] > 0 ) {
      // above benchmark

      if (pc_dict['err'] < pc_dict['diff']) {
        // above benchmark
        bar_colour = progress_bar_colours.rgb[0];
      }
      else {
        // above benchmark, but error bar overlaps with benchmark
        bar_colour = progress_bar_colours.rgb[1];
      }
    }
    else if (pc_dict['diff'] === 0) {
      // at benchmark
      bar_colour = progress_bar_colours.rgb[1];
    }
    else {
      // below benchmark
      if (pc_dict['err'] < Math.abs(pc_dict['diff'])) {
        // below benchmark
        bar_colour = progress_bar_colours.rgb[3];
      }
      else {
        // below benchmark, but error bar overlaps with benchmark
        bar_colour = progress_bar_colours.rgb[2];
      }
    }
    
    // normalise values so diversityXlim = 1 on the axis scale
    var E = (pc_dict['diff'] + 100) / diversityXlim;
    var std = (pc_dict['err']) / diversityXlim;

    var ErrLow = Math.max(E-std, 0);
    var ErrHigh = Math.min(E+std, 100);
    var barOpacity = "1.0";
    var stdOpacity = "0.1"
    // `${traceName.join('<br>')}` // old text in title

    // background line
    var bulletChartTrace1 = {
        type: "indicator",
        mode: "gauge",
        value: 1,
        //number : {font:{family: 'Open-Sans-SB', color:'rgba(160,160,160,1)', size:16}, suffix:"%"},
        domain: { x: [0, 1], y: [0, 1] },
        gauge: {
          shape: "bullet",
          axis: { range: [null, 1], ticksuffix: "%", ticks:"", showticklabels: false},
          bar: { color: "rgba(0,0,0,0)", thickness: 0.5, line: {color: schemeColour[stdOpacity], width: 1}}, // schemeColour[barOpacity] line: {width: 0.1}
          borderwidth:0,
        }
    }

    var bulletChartTrace2 = {
      type: "indicator",
      mode: "gauge",
      value: E,
      //number : {font:{family: 'Open-Sans-SB', color:'rgba(160,160,160,1)', size:16}, suffix:"%"},
      domain: { x: [0, 1], y: [0, 1] },
      //title: {text: 'my_title', font:{family: 'Open-Sans-SB', color:'rgba(160,160,160,1)', size:14}, x:0.5, xanchor:'center', y:0.98, yanchor:'top'},
      //delta: { reference: 100, font:{family: 'Open-Sans-SB', color:'rgba(160,160,160,1)', size:12}, },
      gauge: {
        shape: "bullet",
        axis: { range: [null, 1], ticksuffix: "%", ticks:"", showticklabels: false},
        bar: { color: bar_colour, thickness: 0.5}, // schemeColour[barOpacity] line: {width: 0.1}
        threshold: {
          line: { color: schemeColour["1"], width: 2 },
          thickness: 1.0,
          value: 100/diversityXlim 
        },
        borderwidth:0,
        steps: [
          { range: [ErrLow,ErrHigh], color:schemeColour[stdOpacity], thickness: 0.5}
        ]
      }
  }
    // bullet chart trace to pc dict and save in list for output
    pc_dict['bulletChartTrace'] = [bulletChartTrace1, bulletChartTrace2]
    diversity_levels_withTrace.push(pc_dict);
  }

  return [diversity_levels_withTrace, layout]
}

 