//colour maps 
export const stages_cmap = {'dark_blue': {'hex': '#1C3144',
 'rgba': {'0.1': 'rgba(28, 49, 68, 0.1)',
  '0.2': 'rgba(28, 49, 68, 0.2)',
  '0.3': 'rgba(28, 49, 68, 0.3)',
  '0.4': 'rgba(28, 49, 68, 0.4)',
  '0.5': 'rgba(28, 49, 68, 0.5)',
  '0.6': 'rgba(28, 49, 68, 0.6)',
  '0.7': 'rgba(28, 49, 68, 0.7)',
  '0.8': 'rgba(28, 49, 68, 0.8)',
  '0.9': 'rgba(28, 49, 68, 0.9)',
  '1': 'rgba(28, 49, 68, 1)'}},
'teal': {'hex': '#29ABA4',
 'rgba': {'0.1': 'rgba(41, 171, 164, 0.1)',
  '0.2': 'rgba(41, 171, 164, 0.2)',
  '0.3': 'rgba(41, 171, 164, 0.3)',
  '0.4': 'rgba(41, 171, 164, 0.4)',
  '0.5': 'rgba(41, 171, 164, 0.5)',
  '0.6': 'rgba(41, 171, 164, 0.6)',
  '0.7': 'rgba(41, 171, 164, 0.7)',
  '0.8': 'rgba(41, 171, 164, 0.8)',
  '0.9': 'rgba(41, 171, 164, 0.9)',
  '1': 'rgba(41, 171, 164, 1)'}},
'blue_grey': {'hex': '#5E7D8F',
 'rgba': {'0.1': 'rgba(94, 125, 143, 0.1)',
  '0.2': 'rgba(94, 125, 143, 0.2)',
  '0.3': 'rgba(94, 125, 143, 0.3)',
  '0.4': 'rgba(94, 125, 143, 0.4)',
  '0.5': 'rgba(94, 125, 143, 0.5)',
  '0.6': 'rgba(94, 125, 143, 0.6)',
  '0.7': 'rgba(94, 125, 143, 0.7)',
  '0.8': 'rgba(94, 125, 143, 0.8)',
  '0.9': 'rgba(94, 125, 143, 0.9)',
  '1': 'rgba(94, 125, 143, 1)'}},
'navy': {'hex': '#2E4057',
 'rgba': {'0.1': 'rgba(46, 64, 87, 0.1)',
  '0.2': 'rgba(46, 64, 87, 0.2)',
  '0.3': 'rgba(46, 64, 87, 0.3)',
  '0.4': 'rgba(46, 64, 87, 0.4)',
  '0.5': 'rgba(46, 64, 87, 0.5)',
  '0.6': 'rgba(46, 64, 87, 0.6)',
  '0.7': 'rgba(46, 64, 87, 0.7)',
  '0.8': 'rgba(46, 64, 87, 0.8)',
  '0.9': 'rgba(46, 64, 87, 0.9)',
  '1': 'rgba(46, 64, 87, 1)'}},
'light_grey_blue': {'hex': '#94A5B6',
 'rgba': {'0.1': 'rgba(148, 165, 182, 0.1)',
  '0.2': 'rgba(148, 165, 182, 0.2)',
  '0.3': 'rgba(148, 165, 182, 0.3)',
  '0.4': 'rgba(148, 165, 182, 0.4)',
  '0.5': 'rgba(148, 165, 182, 0.5)',
  '0.6': 'rgba(148, 165, 182, 0.6)',
  '0.7': 'rgba(148, 165, 182, 0.7)',
  '0.8': 'rgba(148, 165, 182, 0.8)',
  '0.9': 'rgba(148, 165, 182, 0.9)',
  '1': 'rgba(148, 165, 182, 1)'}},
'grey_blue': {'hex': '#607C8E',
 'rgba': {'0.1': 'rgba(96, 124, 142, 0.1)',
  '0.2': 'rgba(96, 124, 142, 0.2)',
  '0.3': 'rgba(96, 124, 142, 0.3)',
  '0.4': 'rgba(96, 124, 142, 0.4)',
  '0.5': 'rgba(96, 124, 142, 0.5)',
  '0.6': 'rgba(96, 124, 142, 0.6)',
  '0.7': 'rgba(96, 124, 142, 0.7)',
  '0.8': 'rgba(96, 124, 142, 0.8)',
  '0.9': 'rgba(96, 124, 142, 0.9)',
  '1': 'rgba(96, 124, 142, 1)'}},
'slate_grey': {'hex': '#708090',
 'rgba': {'0.1': 'rgba(112, 128, 144, 0.1)',
  '0.2': 'rgba(112, 128, 144, 0.2)',
  '0.3': 'rgba(112, 128, 144, 0.3)',
  '0.4': 'rgba(112, 128, 144, 0.4)',
  '0.5': 'rgba(112, 128, 144, 0.5)',
  '0.6': 'rgba(112, 128, 144, 0.6)',
  '0.7': 'rgba(112, 128, 144, 0.7)',
  '0.8': 'rgba(112, 128, 144, 0.8)',
  '0.9': 'rgba(112, 128, 144, 0.9)',
  '1': 'rgba(112, 128, 144, 1)'}},
'dark_grey_blue': {'hex': '#2F4F4F',
 'rgba': {'0.1': 'rgba(47, 79, 79, 0.1)',
  '0.2': 'rgba(47, 79, 79, 0.2)',
  '0.3': 'rgba(47, 79, 79, 0.3)',
  '0.4': 'rgba(47, 79, 79, 0.4)',
  '0.5': 'rgba(47, 79, 79, 0.5)',
  '0.6': 'rgba(47, 79, 79, 0.6)',
  '0.7': 'rgba(47, 79, 79, 0.7)',
  '0.8': 'rgba(47, 79, 79, 0.8)',
  '0.9': 'rgba(47, 79, 79, 0.9)',
  '1': 'rgba(47, 79, 79, 1)'}}}

export const pc_cmap = {
  'Ethnicity': {'hex': '#412B61',
  'rgba': {'0.1': 'rgba(65, 43, 97, 0.1)',
    '0.2': 'rgba(65, 43, 97, 0.2)',
    '0.3': 'rgba(65, 43, 97, 0.3)',
    '0.4': 'rgba(65, 43, 97, 0.4)',
    '0.5': 'rgba(65, 43, 97, 0.5)',
    '0.6': 'rgba(65, 43, 97, 0.6)',
    '0.7': 'rgba(65, 43, 97, 0.7)',
    '0.8': 'rgba(65, 43, 97, 0.8)',
    '0.9': 'rgba(65, 43, 97, 0.9)',
    '1': 'rgba(65, 43, 97, 1)'}},
  'SexualOrientation': {'hex': '#F3A438',
    'rgba': {'0.1': 'rgba(243, 164, 56, 0.1)',
     '0.2': 'rgba(243, 164, 56, 0.2)',
     '0.3': 'rgba(243, 164, 56, 0.3)',
     '0.4': 'rgba(243, 164, 56, 0.4)',
     '0.5': 'rgba(243, 164, 56, 0.5)',
     '0.6': 'rgba(243, 164, 56, 0.6)',
     '0.7': 'rgba(243, 164, 56, 0.7)',
     '0.8': 'rgba(243, 164, 56, 0.8)',
     '0.9': 'rgba(243, 164, 56, 0.9)',
     '1': 'rgba(243, 164, 56, 1)'}},
  'Disability': {'hex': '#B63772',
  'rgba': {'0.1': 'rgba(182, 55, 114, 0.1)',
    '0.2': 'rgba(182, 55, 114, 0.2)',
    '0.3': 'rgba(182, 55, 114, 0.3)',
    '0.4': 'rgba(182, 55, 114, 0.4)',
    '0.5': 'rgba(182, 55, 114, 0.5)',
    '0.6': 'rgba(182, 55, 114, 0.6)',
    '0.7': 'rgba(182, 55, 114, 0.7)',
    '0.8': 'rgba(182, 55, 114, 0.8)',
    '0.9': 'rgba(182, 55, 114, 0.9)',
    '1': 'rgba(182, 55, 114, 1)'}},
  'FreeSchoolMeals': {'hex': '#58B09C',
    'rgba': {'0.1': 'rgba(88, 176, 156, 0.1)',
    '0.2': 'rgba(88, 176, 156, 0.2)',
    '0.3': 'rgba(88, 176, 156, 0.3)',
    '0.4': 'rgba(88, 176, 156, 0.4)',
    '0.5': 'rgba(88, 176, 156, 0.5)',
    '0.6': 'rgba(88, 176, 156, 0.6)',
    '0.7': 'rgba(88, 176, 156, 0.7)',
    '0.8': 'rgba(88, 176, 156, 0.8)',
    '0.9': 'rgba(88, 176, 156, 0.9)',
    '1': 'rgba(88, 176, 156, 1)'}},  
  'FirstToAttendUniversity': {'hex': '#ED5456',
     'rgba': {'0.1': 'rgba(237, 84, 86, 0.1)',
       '0.2': 'rgba(237, 84, 86, 0.2)',
       '0.3': 'rgba(237, 84, 86, 0.3)',
       '0.4': 'rgba(237, 84, 86, 0.4)',
       '0.5': 'rgba(237, 84, 86, 0.5)',
       '0.6': 'rgba(237, 84, 86, 0.6)',
       '0.7': 'rgba(237, 84, 86, 0.7)',
       '0.8': 'rgba(237, 84, 86, 0.8)',
       '0.9': 'rgba(237, 84, 86, 0.9)',
       '1': 'rgba(237, 84, 86, 1)'}},
  'Age': {'hex': '#D474EF',
  'rgba': {'0.1': 'rgba(212, 116, 239, 0.1)',
    '0.2': 'rgba(212, 116, 239, 0.2)',
    '0.3': 'rgba(212, 116, 239, 0.3)',
    '0.4': 'rgba(212, 116, 239, 0.4)',
    '0.5': 'rgba(212, 116, 239, 0.5)',
    '0.6': 'rgba(212, 116, 239, 0.6)',
    '0.7': 'rgba(212, 116, 239, 0.7)',
    '0.8': 'rgba(212, 116, 239, 0.8)',
    '0.9': 'rgba(212, 116, 239, 0.9)',
    '1': 'rgba(212, 116, 239, 1)'}},
  'Gender': {'hex': '#6738FE',
  'rgba': {'0.1': 'rgba(103, 56, 254, 0.1)',
    '0.2': 'rgba(103, 56, 254, 0.2)',
    '0.3': 'rgba(103, 56, 254, 0.3)',
    '0.4': 'rgba(103, 56, 254, 0.4)',
    '0.5': 'rgba(103, 56, 254, 0.5)',
    '0.6': 'rgba(103, 56, 254, 0.6)',
    '0.7': 'rgba(103, 56, 254, 0.7)',
    '0.8': 'rgba(103, 56, 254, 0.8)',
    '0.9': 'rgba(103, 56, 254, 0.9)',
    '1': 'rgba(103, 56, 254, 1)'}},
  'Religion': {'hex': '#ED5456',
  'rgba': {'0.1': 'rgba(237, 84, 86, 0.1)',
    '0.2': 'rgba(237, 84, 86, 0.2)',
    '0.3': 'rgba(237, 84, 86, 0.3)',
    '0.4': 'rgba(237, 84, 86, 0.4)',
    '0.5': 'rgba(237, 84, 86, 0.5)',
    '0.6': 'rgba(237, 84, 86, 0.6)',
    '0.7': 'rgba(237, 84, 86, 0.7)',
    '0.8': 'rgba(237, 84, 86, 0.8)',
    '0.9': 'rgba(237, 84, 86, 0.9)',
    '1': 'rgba(237, 84, 86, 1)'}},
  'Sexual Orientation': {'hex': '#F3A438',
    'rgba': {'0.1': 'rgba(243, 164, 56, 0.1)',
     '0.2': 'rgba(243, 164, 56, 0.2)',
     '0.3': 'rgba(243, 164, 56, 0.3)',
     '0.4': 'rgba(243, 164, 56, 0.4)',
     '0.5': 'rgba(243, 164, 56, 0.5)',
     '0.6': 'rgba(243, 164, 56, 0.6)',
     '0.7': 'rgba(243, 164, 56, 0.7)',
     '0.8': 'rgba(243, 164, 56, 0.8)',
     '0.9': 'rgba(243, 164, 56, 0.9)',
     '1': 'rgba(243, 164, 56, 1)'}},
  'Free school meals': {'hex': '#58B09C',
     'rgba': {'0.1': 'rgba(88, 176, 156, 0.1)',
     '0.2': 'rgba(88, 176, 156, 0.2)',
     '0.3': 'rgba(88, 176, 156, 0.3)',
     '0.4': 'rgba(88, 176, 156, 0.4)',
     '0.5': 'rgba(88, 176, 156, 0.5)',
     '0.6': 'rgba(88, 176, 156, 0.6)',
     '0.7': 'rgba(88, 176, 156, 0.7)',
     '0.8': 'rgba(88, 176, 156, 0.8)',
     '0.9': 'rgba(88, 176, 156, 0.9)',
     '1': 'rgba(88, 176, 156, 1)'}},
  'CaringResponsibilty': {'hex': '#ED5456',
       'rgba': {'0.1': 'rgba(237, 84, 86, 0.1)',
         '0.2': 'rgba(237, 84, 86, 0.2)',
         '0.3': 'rgba(237, 84, 86, 0.3)',
         '0.4': 'rgba(237, 84, 86, 0.4)',
         '0.5': 'rgba(237, 84, 86, 0.5)',
         '0.6': 'rgba(237, 84, 86, 0.6)',
         '0.7': 'rgba(237, 84, 86, 0.7)',
         '0.8': 'rgba(237, 84, 86, 0.8)',
         '0.9': 'rgba(237, 84, 86, 0.9)',
         '1': 'rgba(237, 84, 86, 1)'}},
    'VeteranStatus': {'hex': '#D474EF',
         'rgba': {'0.1': 'rgba(212, 116, 239, 0.1)',
           '0.2': 'rgba(212, 116, 239, 0.2)',
           '0.3': 'rgba(212, 116, 239, 0.3)',
           '0.4': 'rgba(212, 116, 239, 0.4)',
           '0.5': 'rgba(212, 116, 239, 0.5)',
           '0.6': 'rgba(212, 116, 239, 0.6)',
           '0.7': 'rgba(212, 116, 239, 0.7)',
           '0.8': 'rgba(212, 116, 239, 0.8)',
           '0.9': 'rgba(212, 116, 239, 0.9)',
           '1': 'rgba(212, 116, 239, 1)'}},
    'EducationLevel': {'hex': '#F3A438',
    'rgba': {'0.1': 'rgba(243, 164, 56, 0.1)',
     '0.2': 'rgba(243, 164, 56, 0.2)',
     '0.3': 'rgba(243, 164, 56, 0.3)',
     '0.4': 'rgba(243, 164, 56, 0.4)',
     '0.5': 'rgba(243, 164, 56, 0.5)',
     '0.6': 'rgba(243, 164, 56, 0.6)',
     '0.7': 'rgba(243, 164, 56, 0.7)',
     '0.8': 'rgba(243, 164, 56, 0.8)',
     '0.9': 'rgba(243, 164, 56, 0.9)',
     '1': 'rgba(243, 164, 56, 1)'}},
    'PartOfLGBTQ': {'hex': '#6738FE',
    'rgba': {'0.1': 'rgba(103, 56, 254, 0.1)',
      '0.2': 'rgba(103, 56, 254, 0.2)',
      '0.3': 'rgba(103, 56, 254, 0.3)',
      '0.4': 'rgba(103, 56, 254, 0.4)',
      '0.5': 'rgba(103, 56, 254, 0.5)',
      '0.6': 'rgba(103, 56, 254, 0.6)',
      '0.7': 'rgba(103, 56, 254, 0.7)',
      '0.8': 'rgba(103, 56, 254, 0.8)',
      '0.9': 'rgba(103, 56, 254, 0.9)',
      '1': 'rgba(103, 56, 254, 1)'}}}
  

export const mevitae_symbol_before = 'square';
export const mevitae_symbol_after = 'circle';

export const progress_bar_colours = {'rgb': ['rgba(65, 43, 97, 1)', 'rgba(65, 43, 97, 0.75)', 'rgba(65, 43, 97, 0.5)', 'rgb(237,84,86, 1)']};



// export const pc_cmap = {
//   'Ethn': {'hex': '#412B61',
//   'rgba': {'0.1': 'rgba(65, 43, 97, 0.1)',
//     '0.2': 'rgba(65, 43, 97, 0.2)',
//     '0.3': 'rgba(65, 43, 97, 0.3)',
//     '0.4': 'rgba(65, 43, 97, 0.4)',
//     '0.5': 'rgba(65, 43, 97, 0.5)',
//     '0.6': 'rgba(65, 43, 97, 0.6)',
//     '0.7': 'rgba(65, 43, 97, 0.7)',
//     '0.8': 'rgba(65, 43, 97, 0.8)',
//     '0.9': 'rgba(65, 43, 97, 0.9)',
//     '1': 'rgba(65, 43, 97, 1)'}},
//   'Disa': {'hex': '#B63772',
//   'rgba': {'0.1': 'rgba(182, 55, 114, 0.1)',
//     '0.2': 'rgba(182, 55, 114, 0.2)',
//     '0.3': 'rgba(182, 55, 114, 0.3)',
//     '0.4': 'rgba(182, 55, 114, 0.4)',
//     '0.5': 'rgba(182, 55, 114, 0.5)',
//     '0.6': 'rgba(182, 55, 114, 0.6)',
//     '0.7': 'rgba(182, 55, 114, 0.7)',
//     '0.8': 'rgba(182, 55, 114, 0.8)',
//     '0.9': 'rgba(182, 55, 114, 0.9)',
//     '1': 'rgba(182, 55, 114, 1)'}},
//   'Ages': {'hex': '#D474EF',
//   'rgba': {'0.1': 'rgba(212, 116, 239, 0.1)',
//     '0.2': 'rgba(212, 116, 239, 0.2)',
//     '0.3': 'rgba(212, 116, 239, 0.3)',
//     '0.4': 'rgba(212, 116, 239, 0.4)',
//     '0.5': 'rgba(212, 116, 239, 0.5)',
//     '0.6': 'rgba(212, 116, 239, 0.6)',
//     '0.7': 'rgba(212, 116, 239, 0.7)',
//     '0.8': 'rgba(212, 116, 239, 0.8)',
//     '0.9': 'rgba(212, 116, 239, 0.9)',
//     '1': 'rgba(212, 116, 239, 1)'}},
//   'Gend': {'hex': '#6738FE',
//   'rgba': {'0.1': 'rgba(103, 56, 254, 0.1)',
//     '0.2': 'rgba(103, 56, 254, 0.2)',
//     '0.3': 'rgba(103, 56, 254, 0.3)',
//     '0.4': 'rgba(103, 56, 254, 0.4)',
//     '0.5': 'rgba(103, 56, 254, 0.5)',
//     '0.6': 'rgba(103, 56, 254, 0.6)',
//     '0.7': 'rgba(103, 56, 254, 0.7)',
//     '0.8': 'rgba(103, 56, 254, 0.8)',
//     '0.9': 'rgba(103, 56, 254, 0.9)',
//     '1': 'rgba(103, 56, 254, 1)'}},
//   'Reli': {'hex': '#ED5456',
//   'rgba': {'0.1': 'rgba(237, 84, 86, 0.1)',
//     '0.2': 'rgba(237, 84, 86, 0.2)',
//     '0.3': 'rgba(237, 84, 86, 0.3)',
//     '0.4': 'rgba(237, 84, 86, 0.4)',
//     '0.5': 'rgba(237, 84, 86, 0.5)',
//     '0.6': 'rgba(237, 84, 86, 0.6)',
//     '0.7': 'rgba(237, 84, 86, 0.7)',
//     '0.8': 'rgba(237, 84, 86, 0.8)',
//     '0.9': 'rgba(237, 84, 86, 0.9)',
//     '1': 'rgba(237, 84, 86, 1)'}},
//     'Sexu': {'hex': '#F3A438',
//     'rgba': {'0.1': 'rgba(243, 164, 56, 0.1)',
//      '0.2': 'rgba(243, 164, 56, 0.2)',
//      '0.3': 'rgba(243, 164, 56, 0.3)',
//      '0.4': 'rgba(243, 164, 56, 0.4)',
//      '0.5': 'rgba(243, 164, 56, 0.5)',
//      '0.6': 'rgba(243, 164, 56, 0.6)',
//      '0.7': 'rgba(243, 164, 56, 0.7)',
//      '0.8': 'rgba(243, 164, 56, 0.8)',
//      '0.9': 'rgba(243, 164, 56, 0.9)',
//      '1': 'rgba(243, 164, 56, 1)'}},
//      'Free school meals': {'hex': '#58B09C',
//      'rgba': {'0.1': 'rgba(88, 176, 156, 0.1)',
//      '0.2': 'rgba(88, 176, 156, 0.2)',
//      '0.3': 'rgba(88, 176, 156, 0.3)',
//      '0.4': 'rgba(88, 176, 156, 0.4)',
//      '0.5': 'rgba(88, 176, 156, 0.5)',
//      '0.6': 'rgba(88, 176, 156, 0.6)',
//      '0.7': 'rgba(88, 176, 156, 0.7)',
//      '0.8': 'rgba(88, 176, 156, 0.8)',
//      '0.9': 'rgba(88, 176, 156, 0.9)',
//      '1': 'rgba(88, 176, 156, 1)'}}}
  


// export const mevitae_grey = {
//   'hex': '#c9c9c9',
//   'rgba': {'0.1': 'rgba(201, 201, 201, 0.1)',
//     '0.2': 'rgba(201, 201, 201, 0.2)',
//     '0.3': 'rgba(201, 201, 201, 0.3)',
//     '0.4': 'rgba(201, 201, 201, 0.4)',
//     '0.5': 'rgba(201, 201, 201, 0.5)',
//     '0.6': 'rgba(201, 201, 201, 0.6)',
//     '0.7': 'rgba(201, 201, 201, 0.7)',
//     '0.8': 'rgba(201, 201, 201, 0.8)',
//     '0.9': 'rgba(201, 201, 201, 0.9)',
//     '1': 'rgba(201, 201, 201, 1)'}
// }

// export const mevitae_grey = {
//   'hex': '#a1a8af',
//   'rgba': {'0.1': 'rgba(161, 168, 175, 0.1)',
//   '0.2': 'rgba(161, 168, 175, 0.2)',
//   '0.3': 'rgba(161, 168, 175, 0.3)',
//   '0.4': 'rgba(161, 168, 175, 0.4)',
//   '0.5': 'rgba(161, 168, 175, 0.5)',
//   '0.6': 'rgba(161, 168, 175, 0.6)',
//   '0.7': 'rgba(161, 168, 175, 0.7)',
//   '0.8': 'rgba(161, 168, 175, 0.8)',
//   '0.9': 'rgba(161, 168, 175, 0.9)',
//   '1': 'rgba(161, 168, 175, 1)',
// }
// }

export const mevitae_grey = {
  hex: '#a1a8af',
  rgba: opacity => `rgba(161, 168, 175, ${opacity})`,
};

export const mevitae_purple = {
  'hex': '#2d1145',
  'rgba': 'rgba(45, 17, 69, 1)'
 }
 
 //'rgb(182, 191, 201, 1)'  previous grey colour used for the barchart... not consistent

export const PLOTLY_CONFIG = {
   MODEBAR_REMOVE : ['zoom', 'pan', 'select', 'zoomIn', 'zoomOut', 'autoScale', 'resetScale', 'zoom2d', 'pan2d', 
  'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d', 'drawline', 'drawopenpath', 'drawclosedpath', 
  'drawcircle', 'drawrect', 'eraseshape', 'zoom3d', 'pan3d', 'orbitRotation', 'tableRotation', 'handleDrag3d', 'resetCameraDefault3d', 
  'resetCameraLastSave3d', 'hoverClosest3d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'zoomInGeo', 'zoomOutGeo', 'resetGeo', 
  'hoverClosestGeo', 'hoverClosestGl2d', 'hoverClosestPie', 'toggleHover', 'resetViews', 'sendDataToCloud', 'toggleSpikelines', 
  'resetViewMapbox'], //keep: 'toImage'
  MODEBAR_KEEP : ['toImage']

}

export const openSans_semiBold = {
  family: 'Open Sans',
  weight: 600,
  size:12,
  //color: 'rgba(160,160,160,1)'
  color: 'rgba(161, 168, 175,1)'
}

export const openSans_xticks = {
  family: 'Open Sans',
  weight: 300,
  size:12,
  // color: 'rgba(160,160,160,1)'
  color: 'rgba(161, 168, 175,1)'
}

export const openSans_light = {
  family: 'Source Sans Pro',
  weight: 300,
  size:12,
  //color: 'rgba(160,160,160,1)'
  color: 'rgba(161, 168, 175,1)'
}

