// function for taking a sum
export function add(accumulator, a) {
    return accumulator + a;
}

export function sum(xs) {
    return xs.reduce((a, b) => a + b, 0);
}

export function getMaxCandidateResponses(diversityStepResult) {
    // For each protected characteristic, get all the candidates that are in the first stage, 
    // so we sum over the different groups.
    const firstStageCandidateResponses = Object.values(
        diversityStepResult['DiversityAgainstStepGraph'],
    ).map(value => sum(value['RawNumbers'][0]['NumberOfEachGroup']));
    // Then return the highest number of candidate responses.
    return Math.max(...firstStageCandidateResponses);
}

export function getMaxCandidateResponsesFromTotalCounts(total_counts_job) {
    let counts_step_1 = total_counts_job[0]
    const candidatesAcrossPCs = counts_step_1.map(value => value[1])
    return Math.max(...candidatesAcrossPCs);
}