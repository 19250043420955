//holds utility functions used in graphs or data-requests 

//equivalent to numpy linspace function 
export function linspace(start, stop, num) {
    var step = (stop - start) / Math.max(num - 1, 1);
    return Array.from({length: num}, (_, i) => start + (step * i));
  }

//list to initials 
export function initalise(listToInitialise){
      var initialsList = []
      for (var ii = 0; ii < listToInitialise.length; ii++) {
        var initials = listToInitialise[ii].match(/\b(\w)/g);
        initialsList.push(initials.join(''));
       }
       return initialsList
  }
  
//calculate the y ranges for a diversity step plot
export function yRangeDS(diversityStepData){
//takes the diversityStepData from getDiversityStepData and returns the y range

 
  var plotRange = [Math.min(...diversityStepData.y), Math.max(...diversityStepData.y)];
  if(diversityStepData.benchmark){
    if (diversityStepData.benchmark<plotRange[0]){
      plotRange[0] = diversityStepData.benchmark
    }
    if (diversityStepData.benchmark>plotRange[1]){
      plotRange[1] = diversityStepData.benchmark
    }
  }

  plotRange[0] -= 1;
  if (plotRange[0]<0){
    plotRange[0] = 0;
  }
  plotRange[1] +=1;



  return plotRange
}

//generate a colour scale using 'rgba(x,x,x,x)' values
export function genColorScale(startColour, endColour, numSteps) {
  // Parse the RGBA values from the strings
  startColour = startColour.substring(5, startColour.length - 1).split(",").map(Number);
  endColour = endColour.substring(5, endColour.length - 1).split(",").map(Number);
  var alpha = startColour[3]; 
  // Compute the color increments for each RGBA component
  const colorIncrement = endColour.map((val, i) => (val - startColour[i]) / numSteps);

  // Generate the colorscale by interpolating the RGBA values between the start and end colors
  const colors = [];
  for (let j = 0; j < numSteps; j++) {
      const color = startColour.map((val, i) => Math.round(val + j * colorIncrement[i]));
      color[3] = alpha;
      colors.push(`rgba(${color.join(",")})`);
  }
  return colors;
}

//find pc either side of pc in dictionary 
export function findNeighbours(obj, key) {
  const keys = Object.keys(obj);
  const index = keys.indexOf(key);
  let prevKey = index === 0 ? keys[keys.length - 1] : keys[index - 1];
  let nextKey = index === keys.length - 1 ? keys[0] : keys[index + 1];
  return [prevKey, nextKey];
}

//check that two arrays are equal
export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

//split list of words into chunks of length 2 (for bullet chart titles)
export function splitIntoChunks(string) {
  var words = string.split(' ');
  var chunks = [];

  for (var i = 0; i < words.length; i += 2) {
    chunks.push(words.slice(i, i + 2).join(' '));
  }

  return chunks;
}

export function checkStagesMatch(MergedData){
  //check stages in figure result match and then return those stages 
  var allMatch = true;
  var referenceList = [];

  for (var graphType in MergedData){
      for (var jobResult in MergedData[graphType]){
        var currentList = MergedData[graphType][jobResult].StageLabels;
        if (referenceList.length == 0){
            referenceList = currentList;
        }
        else {
            if (!arraysEqual(referenceList, currentList)){
                allMatch = false;
            }
        }

      }
     
  }

  if (allMatch == true){
    return [allMatch, referenceList]
  } 
  else{
    return [allMatch, null]
  }
  
}


export function checkPCMatch(MergedData){
  //check pcs match in figure result and return pcs 
  var allMatch = true;
  var referenceList = [];
  for (var graphType in MergedData){
    for (var jobResult in MergedData[graphType]){
      var categoriesData = MergedData[graphType][jobResult]['CategoriesData']['Categories'];
      var currentList = Object.keys(categoriesData).map(key => categoriesData[key].ID);
      if (referenceList.length == 0){
          referenceList = currentList;
      }
      else {
          if (!arraysEqual(referenceList, currentList)){
              allMatch = false;
          }
      }
    }   
  }

  if (allMatch == true){
    return [allMatch, referenceList]
  } 
  else{
    return [allMatch, null]
  }
}

export function handleDownloadClick(event, plotElements) {
  downloadPlot(plotElements);
}

function downloadPlot(plotElements) {
  // Use plotly to capture the each plot element in plotElements as an image
  for (let ii = 0; ii < plotElements.length; ii++) {
    const element = plotElements[ii];
    Plotly.downloadImage(element, {
      format: 'png', // or 'jpeg', 'webp'
      filename: `${element.id}` 
    });
    
  }
  
}

export function getFilterTitles(MetaData){
  return [MetaData['filter_name_1'], MetaData['filter_name_2']]
}

export function splitter(str, l){
  var strs = [];
  while(str.length > l){
      var pos = str.substring(0, l).lastIndexOf(' ');
      pos = pos <= 0 ? l : pos;
      strs.push(str.substring(0, pos));
      var i = str.indexOf(' ', pos)+1;
      if(i < pos || i > pos+l)
          i = pos;
      str = str.substring(i);
  }
  strs.push(str);
  return strs;
}

export function formatTickLabels(tickLabels, maxCharacters=14) {
  // if a ticklabel is larger than 14 characters, then we split
  // it up onto multiple lines

  var adjustedTickLabels = [];
  for (let jj = 0; jj < tickLabels.length; jj++) {
    let strings = splitter(tickLabels[jj], maxCharacters);
    adjustedTickLabels.push(strings.join("<br>"));
  }
  return adjustedTickLabels;
}

export function mergeTickLabel(tickLabel) {
  // merge a ticklabel, that has potentially been split up on multiple lines, 
  // back into the original string
  let originalTickLabel = tickLabel.split("<br>");
  return originalTickLabel.join(" ");
}

const legendYSpacingByLines = {
  1: -0.1,
  2: -0.12,
  3: -0.16,
  4: -0.2,
  5: -0.23
}

export function legendYLocationFromTickLines(xTickLabels) {
  // calculate max number of rows of xtick labels
  // then manually map that to an appropriate y-position for the legend,
  // which is currently just based on a trial-and-error process..
  var maxLines = 0;
  for (let jj = 0; jj < xTickLabels.length; jj++) {
    let numberOfLines = xTickLabels[jj].split("<br>").length;
    if (numberOfLines > maxLines) {
      maxLines = numberOfLines;
    }
  }

  return legendYSpacingByLines[maxLines];
}