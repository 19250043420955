import { getDataSetInfo } from "./data-requests/api-requests";
import { openModal, closeModal } from "./ux_utils";
import { getFigureUpdates, updateCurrentTime } from "./data-requests/settings_data_requests.js";

export async function getDataSetsForPage(page) {

    let version = "None"
    // see if there's a specific version asked for
    if (localStorage.getItem('version')) {
        version = localStorage.getItem('version');
    }
    console.log('version:', version);

    let dataSetInfos = await getDataSetInfo(sessionStorage.getItem("DomainId"), sessionStorage.getItem("IntegrationSettingsId"), page, version);
    console.log(dataSetInfos);

    let dataSets = [];
    for (var dataSetInfo of dataSetInfos) {

        // look at first available job to get the version
        // this means there is only ONE version per dataset, is that ok?
        let pending = false;
        for (let job of dataSetInfo["jobInfos"]) {
            if (job["status"] != "not found") {
                let job_version = job["version"]
                if (job_version === version) {

                    // set preliminary title and description, in case metadata is not available
                    let title = dataSetInfo["dataSetId"]
                    let description = "no description"

                    // try to get metadata
                    if (job["metadata"] != "null") {
                        let metadata = JSON.parse(JSON.parse(job["metadata"]));
                        title = metadata['Title'],
                        description = metadata['Description']
                    }
                    if (job_version != "None") {
                        // if it's not the default version, we append the version name
                        description = description + " (version: " + job_version + ")"
                    }
                    if (job["status"] === "pending") {
                        pending = true;
                        title = title + ' (pending)'
                    }
                    dataSets.push( {
                        'title': title,
                        'description': description,
                        'dataSetId': dataSetInfo['dataSetId'],
                        'pending': pending,
                        'version': job_version,
                    });
                }
                break;
            }
        }

        // for now we just ignore the datasets for which figures have not been found
        // TODO: in future we'd like to pull metadata from figure request which we can populate the cards with
        // if (dataSetInfo["status"] === "not found") {
        //     continue;
        // }
        
        // var metadata = JSON.parse(JSON.parse(dataSetInfo['metadata']));

        // let pending = false
        // let title = metadata['Title']
        // if (dataSetInfo["status"] === "pending") {
        //     title = title + ' (pending)'
        //     pending = true
        // }

        // var dataSet = {
        //     'title': title,
        //     'description': metadata['Description'],
        //     'dataSetId': dataSetInfo['dataSetId'],
        //     'pending': pending,
        //     'version': dataSetInfo["version"],
        // }
        // dataSets.push(dataSet);
    }
    return dataSets;   
}


export function populateDatasetCards(cardContainer, dataSets, page) {
    // datasets are the output from the API
    // page is either 'block' or 'impact'

    // loop through datasets collected from API
    dataSets.forEach(element => {

        // For each figure, create a div called card and style with the "Sample Card" class
        const style = document.getElementById("block-card")

        // Copy the card and it's style
        const card = style.cloneNode(true)

        card.setAttribute('id', '');
        card.style.display = 'block';

        // When a card is clicked, navigate to the item page by passing the block id
        // only add link if status is not pending
        let click_area = card.getElementsByClassName('card-text-collection')[0];
        var linkpage = 'block';
        if (page === 'impact') {
            linkpage = 'comparison';
        }
        if (element['pending'] === false) {
            click_area.addEventListener('click', function() {
                document.location.href = "/" + linkpage + "?dataSetID=" + element.dataSetId;
            });
        }

        // set up delete dataset interaction
        let delete_dataset_icon = card.getElementsByClassName('remove-button')[0];
        delete_dataset_icon.addEventListener('click', function() {
            openModal();
            sessionStorage.setItem('dataset_todelete', element.dataSetId);
        });

        // For each block, set the text content of the header to the block's title
        const header = card.getElementsByClassName('card-header')[0]
        header.textContent = element.title;

        // same for the description
        const description = card.getElementsByClassName('card-description')[0]
        description.textContent = element.description;


        // Place the card into the div "Cards-Container" 
        cardContainer.appendChild(card);
    });
}

export async function checkForUpdates(loadPageFunction) {
    // make sure to pass in the loadPageFunction, which is a function defined specifically for each page.
    // hence this checkForUpdates function can be used for different pages
    let figureUpdates = await getFigureUpdates(sessionStorage.getItem('DomainId'), sessionStorage.getItem('IntegrationSettingsId'));
    console.log(figureUpdates);
    let lastUpdateTime = figureUpdates.lastUpdateTime;
    if (lastUpdateTime != figureUpdates.currentlyUsedTime) {
        // then show the popup and set up its interactions
        let updatePopup = document.getElementsByClassName('refresh-popup')[0];
        updatePopup.style.display = 'block';
        let refreshButton = updatePopup.getElementsByClassName('link')[0];
        refreshButton.addEventListener('click', async function() {
            let updateResult = await updateCurrentTime(sessionStorage.getItem('DomainId'), sessionStorage.getItem('IntegrationSettingsId'));
            updatePopup.style.display = 'none';
            loadPageFunction();
        })
    }
}
