//const API_URL = "https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/"
const API_URL = "https://ald1-eu.mevitae.net/"
// used to be this for development: "https://api-analytics.mevitae.dev/"

export const DEV_ENDPOINTS = {

    //structure:
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/result/{domainId}/{figureId}
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/result/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/4446
    //response: FigureResult.json
    FIGURE_RESULT : API_URL + "api/results",

    //POST a page request for a page
    //structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/request/pageRequest/{domainId}/{integrationSettingId}/{frontendPage}/{requestTime}/{figureRequestId}
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/request/pageRequest/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86/comparison_page/1682447776/1234
    //body: AnalyticsPageRequest.json

    //GET page requests for page
    //structure: 
    //           https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/result/pageRequest/{domainId}/{integrationSettingId}/{frontendPage}
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/result/pageRequest/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86/comparison_page
    //response: AnalyticsPageRequests.json
    PAGE_REQUEST : API_URL + "api/requests/pageRequest",

    DATASET_STRUCTURE : API_URL + "api/datasetstructure",

    DELETE_DATASET : API_URL + "api/deleteDataSet",

    //POST figure request template
    FIGURE_REQUEST_TEMPLATES : API_URL + "api/requests/figureRequestTemplates",
    // new version
    //FIGURE_REQUEST_TEMPLATES : "https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/requests/figureRequestTemplates",

    //POST figure request
    FIGURE_REQUEST: API_URL + "api/requests/figureRequest",

    //GET mapping by PC
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/mapping/pcMapping/{domainId}/{integrationSettingsId}/{protectedCharacteristic}
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/mapping/pcMapping/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86/Ethnicity
    //response: ProtectedCharacteristicMapping.json

    //POST a PC mapping
    //structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/request/pcMapping
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/request/pcMapping
    //body: ProtectedCharacteristicMapping.json

    PC_MAPPING : API_URL + "api/mappings/pcMapping",

    //GET the license for an integration
    //structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/license/{domainId}/{integrationSettingsId} //BM: I think you need to leave out integrationSettingsId
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/license/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86
    //response: AnalyticsLicense.json
    LICENSE : API_URL + "api/licenses",

    //GET filterable for an integration
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/filterable/{domainId}/{integrationSettingsId}
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/filterable/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86
    //response: AnalyticsFilterable.json
    FILTERABLE : API_URL + "api/settings/filterable",

    //GET filters for an integration
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/filter/{domainId}/{integrationSettingsId}
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/filter/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86
    //response: AnalyticsFilters.json

    //POST filter for an integration
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/filter
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/filter
    //body: AnalyticsFilter.json

    FILTER : API_URL + "api/settings/filter",

    //GET workflow mappings for integration
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/mapping/workflowStatusMapping/{domainId}/{integrationSettingsId}
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/mapping/workflowStatusMapping/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86
    //response: AnalyticsWorkflowStatusMapping.json

    //POST a workflow mapping
    //structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/request/workflowStatusMapping
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/request/workflowStatusMapping
    //body: AnalyticsWorkflowStatusMapping.json

    WORKFLOW_MAPPING : API_URL + "api/requests/workflowStatusMapping",

    //GET workflows for an integration
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/workflows/{domainId}/{integrationSettingsId}
    //response: AnalyticsWorkflows.json

    AVAILABLE_WORKFLOWS : API_URL + "api/workflows",
    
    //GET benchmark for PC
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/benchmark/{domainId}/{protectedCharacteristic}
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/benchmark/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86/Gender
    //response: AnalyticsBenchmark.json

    
    //POST benchmark for PC
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/benchmark
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/settings/benchmark
    //body: AnalyticsBenchmark.json

    BENCHMARK : API_URL + "api/settings/benchmark",

    //GET questionnaires
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/questionnaires/{domainId}/{integrationSettingsId}
    //example: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/questionnaires/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86

    QUESTIONNAIRES : API_URL + "api/questionnaires",


    //GET questionnaire questions
    ///structure: we added "?" as this is optional and you may wish to retrieve all questions for a questionnaire
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/questionnaires/questions/{analyticsQuestionnaireId}?protectedCharacteristic={protectedCharacteristic}
    //example: 

    //          https://api-analytics.mevitae.dev/api/questionnaires/questions/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6DMeVitae86MeVitae10?protectedCharacteristic=Gender
    QUESTIONNAIRE_QUESTIONS : API_URL + "api/questionnaires/questions",

    //GET possible answers for a PC
    ///structure: 
    //          https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/questionnaires/possibleAnswers/{domainId}/{integrationSettingsId}/{protectedCharacteristic}
    //example: 
    //           https://8aee95a1-3731-48dd-aa42-0b1fe6449494.mevitae.net/api/questionnaires/possibleAnswers/62-72-69-74-69-73-68-6C-61-6E-64-2E-63-6F-6D/86/Gender
    //response: PossibleAnswers.json

    QUESTIONNAIRE_POSSIBLE_ANSWERS : API_URL + "api/questionnaires/possibleAnswers",

    FIGURE_UPDATES : API_URL + "api/figureUpdates",

}
