
import { fetchPlus } from "./api-requests.js";
import { DEV_ENDPOINTS } from "../endpoints.js";

export async function getPossibleAnswers(domainId, integrationSettingsId, protectedCharacteristic){
    let url = DEV_ENDPOINTS.QUESTIONNAIRE_POSSIBLE_ANSWERS + "/" + domainId + "/" + integrationSettingsId + "/" + protectedCharacteristic;

    let jsonResponse = await fetchPlus(url, {
        method: 'GET'
    }).then(response => {
        if (response.ok)
            return response.json()
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function getPCMapping(domainId, integrationSettingsId, protectedCharacteristic){
    let url = DEV_ENDPOINTS.PC_MAPPING + "/" + domainId + "/" + integrationSettingsId + "/" + protectedCharacteristic;

    let jsonResponse = await fetchPlus(url, {
        method: 'GET'
    }).then(response => {
        if (response?.ok)
            return response.json()
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function getLicense(domainId, integrationSettingsId) {
    let url = DEV_ENDPOINTS.LICENSE + "/" + domainId  //+ "/" + integrationSettingsId;

    let jsonResponse = await fetchPlus(url, {
        method: 'GET'
    }).then(response => {
        if (response?.ok)
            return response.json()
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function getMappingsForAllPcs(domainId, integrationSettingsId){
    // get the license
    let license = await getLicense(domainId, integrationSettingsId);
    let licensedPcs = license["integrationLicenses"][integrationSettingsId]["questionnaireLicenses"][0]["protectedCharacteristics"];

    // order pc mappings in dict key -> pc, value -> list of mappings
    let pc_dict = {};
    for (var pc of licensedPcs) {
        let pc_mappings = await getPCMapping(domainId, integrationSettingsId, pc);
        pc_dict[pc] = pc_mappings;
    }

    return pc_dict;
}

export async function getAvailableWorkflows(domainId, integrationSettingsId){
    let url = DEV_ENDPOINTS.AVAILABLE_WORKFLOWS + "/" + domainId + "/" + integrationSettingsId;

    let jsonResponse = await fetchPlus(url, {
        method: 'GET'
    }).then(response => {
        if (response?.ok)
            return response.json()
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function getFilterables(domainId, integrationSettingsId){
    let url = DEV_ENDPOINTS.FILTERABLE + "/" + domainId + "/" + integrationSettingsId;

    let jsonResponse = await fetchPlus(url, {
        method: 'GET'
    }).then(response => {
        if (response?.ok)
            return response.json()
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function getBenchmarks(domainId, protectedCharacteristic){
    let url = DEV_ENDPOINTS.BENCHMARK + "/" + domainId + "/" + protectedCharacteristic;

    let jsonResponse = await fetchPlus(url, {
        method: 'GET'
    }).then(response => {
        if (response?.ok)
            return response.json()
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function getQuestionnaires(domainId, integrationSettingsId){
    let url = DEV_ENDPOINTS.QUESTIONNAIRES + "/" + domainId + "/" + integrationSettingsId;

    let jsonResponse = await fetchPlus(url, {
        method: 'GET'
    }).then(response => {
        if (response?.ok)
            return response.json()
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function getQuestionnaireQuestion(analyticsQuestionnaireId, protectedCharacteristic){
    let url = DEV_ENDPOINTS.QUESTIONNAIRE_QUESTIONS + "/" + analyticsQuestionnaireId + "?protectedCharacteristic={" + protectedCharacteristic + "}";

    let jsonResponse = await fetchPlus(url, {
        method: 'GET'
    }).then(response => {
        if (response?.ok)
            return response.json()
        else
            console.log(response.json())
    });

    return jsonResponse;
}


//////// POST requests /////////
export async function postFilter(postBody){
    let url = DEV_ENDPOINTS.FILTER

    let jsonResponse = await fetchPlus(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postBody)
        }).then(response => {
        if (response?.ok)
            return response
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function postPageRequest(domainId, integrationSettingsId, frontendPage, requestTime, figureRequestId, requestName, requestDescription){
    let url = DEV_ENDPOINTS.PAGE_REQUEST +  "/" + domainId + "/" + integrationSettingsId + "/" + frontendPage + "/" + requestTime + "/" + figureRequestId + "/" + requestName + "/" + requestDescription;

    let jsonResponse = await fetchPlus(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (response?.ok)
            return response
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function postFigureRequestTemplate(postBody, page){
    let url = DEV_ENDPOINTS.FIGURE_REQUEST_TEMPLATES + '/' + page;

    let jsonResponse = await fetchPlus(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postBody)
        }).then(response => {
        if (response?.ok)
            return response
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function postFigureRequest(postBody){
    let url = DEV_ENDPOINTS.FIGURE_REQUEST;

    let jsonResponse = await fetchPlus(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postBody)
        }).then(response => {
        if (response?.ok)
            return response
        else
            console.log(response.json())
    });

    return jsonResponse;
}
export async function postPCMapping(postBody){
    let url = DEV_ENDPOINTS.PC_MAPPING;

    let jsonResponse = await fetchPlus(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postBody)
        }).then(response => {
        if (response?.ok)
            return response
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function postBenchmark(postBody){
    let url = DEV_ENDPOINTS.BENCHMARK;

    let jsonResponse = await fetchPlus(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postBody)
        }).then(response => {
        if (response?.ok)
            return response
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function updateCurrentTime(domainId, integrationSettingsId){
    let url = DEV_ENDPOINTS.FIGURE_UPDATES + '/updateCurrentTime/' + domainId + '/' + integrationSettingsId;

    let jsonResponse = await fetchPlus(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        }).then(response => {
        if (response?.ok)
            return response
        else
            console.log(response.json())
    });

    return jsonResponse;
}

export async function getFigureUpdates(domainId, integrationSettingsId){
    let url = DEV_ENDPOINTS.FIGURE_UPDATES + '/' + domainId + '/' + integrationSettingsId;

    let jsonResponse = await fetchPlus(url, {
        method: 'GET'
    }).then(response => {
        if (response?.ok)
            return response.json()
        else
            console.log(response.json())
    });

    return jsonResponse;
}