import {DEV_ENDPOINTS} from "../endpoints.js";

function navigateToLogin() {
    location.href = "https://accounts.mevitae.com/";
}

export function fetchPlus(url, options = {}, retries = 1, timeout = 1000 * 100) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    options.signal = controller.signal
    // send authorization tokens
    if (!options.headers) options.headers = {};
    const userToken = sessionStorage.getItem('userToken');
    const serviceToken = sessionStorage.getItem('serviceToken');
    if (!userToken || !serviceToken) navigateToLogin();
    options.headers['Authorization'] = 'bearer ' + userToken;
    options.headers['X-MeVitae-Service-Token'] = serviceToken;

    return fetch(url, options).then(res => {
        clearTimeout(timeoutId);
        // TODO: refresh tokens on 403 response, not just ask to log in again
        if (res.status === 401 || res.status === 403) navigateToLogin();
        if (!res.ok && retries > 0) return fetchPlus(url, options, retries - 1, timeout);
        return res;
    });
}

export function getFigureResult(domainId, integrationSettingId, pageType, datasetID) {
    const url = DEV_ENDPOINTS.FIGURE_RESULT + "/" + domainId + "/" + integrationSettingId + "/" + pageType + "/" + datasetID;

    return fetchPlus(url, {
        method: 'GET',
    }).then(response => {
        if (response.ok) return response.json();
        throw new Error("Get figure result response not OK");
    });
}

export function getPageRequests(domainId, integrationSettingId, frontendPage) {
    const url = DEV_ENDPOINTS.PAGE_REQUEST + "/" + domainId + "/" + integrationSettingId + "/" + frontendPage;

    return fetchPlus(url, {
        method: 'GET',
    }).then(response => {
        if (response.ok) return response.json();
        throw new Error("Get page result response not OK");
    });
}

export function getDataSetInfo(domainId, integrationSettingsId, page, requestedVersion) {
    const url = DEV_ENDPOINTS.DATASET_STRUCTURE + "/" + domainId + "/" + integrationSettingsId + "/" + page + "/" + requestedVersion;
    
    return fetchPlus(url, {
        method: 'GET',
    }).then(response => {
        if (response.ok) return response.json();
        throw new Error("Get dataset Info response not OK");
    });
}

export async function deleteDataSet(domainId, integrationSettingsId, page, dataSetId) {
    const url = DEV_ENDPOINTS.DELETE_DATASET + "/" + domainId + "/" + integrationSettingsId + "/" + page + "/" + dataSetId;
    // nothing is done with this postBody on the backend, but POST request can't be sent without a body
    var postBody = {dataSetId: dataSetId};

    let jsonResponse = await fetchPlus(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postBody) 
        }).then(response => {
        if (response?.ok)
            return response
        else
            console.log(response.json())
    });

    return jsonResponse;
}
