import { getFigureResult, getDataSetInfo } from '../data-requests/api-requests.js'
import { generateDiversityLevelsTraceLayout, calculateDiversityXlim } from '../graphs/home/bullet-chart-home.js';
import { generateBarChartTraceLayout, plotCompositionBar } from '../graphs/home/diversity-step-bar-home.js';
import { checkForUpdates } from '../structure_utils.js';
import { stopLoader, setUserInitials, setWelcomeMessage, initToken, setLogOutButton } from "../ux_utils.js";

// constants
const config = {
    //modeBarButtonsToRemove : PLOTLY_CONFIG.MODEBAR_REMOVE,
    //displaylogo : false
    displayModeBar: false
};

/**
 * 
 * @param {*} output_diversity_levels dictionary with 'low_diversity' and 'high_diversity' options, each in format [{'pc': 'Ethnicity', 'diff': 20, 'err': 3, 'sa': 'above_benchmark'}, 
 * {'pc': 'Age', 'diff': 5, 'err': 2, 'sa': 'above_benchmark'}] (output from analytics' 'calculate_good_bad_diversity_levels()')
 * @param diversity_class string, one of 'low_diversity' or 'high_diversity'
 * function
 */
function plotBulletChartForClass(traceDict, layout, diversity_class, biases, homeDataSetID) {

    // grab html sample element to put plots in. this contains a plot div, a pc text div, a number div and a tagname text div (for above/below benchmark)
    let diversity_progress_samplediv = document.getElementById('diversity-progress-sample');

    // grab html collection div to add bullet chart to
    let diversity_progress_collectiondiv = document.getElementById((diversity_class+'-collection'));

    // populate all elements
    for (let pc_dict of traceDict) {

        let diversity_progress_div = diversity_progress_samplediv.cloneNode(true);
        diversity_progress_div.setAttribute('id', '');
        diversity_progress_div.style.display = 'block';

        // pc title
        let pc_title_div = diversity_progress_div.getElementsByClassName('pc-title')[0];
        pc_title_div.textContent = pc_dict['pc']

        // difference in number
        let difference_div = diversity_progress_div.getElementsByClassName('difference')[0];
        let difference_text = '';
        // no percentage shown when consistent with benchmark
        let diff = pc_dict['diff'];
        if (Math.abs(diff) < 1) {
            diff = Math.round(diff * 100) / 100;
        } else {
            diff = Math.round(diff);
        }
        if (pc_dict['sa'] === 'at_benchmark') {
            difference_text = '';
        }
        else if (pc_dict['diff'] > 0) {
            difference_text = '+' + diff.toString() + '%'
        }
        else if (pc_dict['diff'] < 0) {
            difference_text = diff.toString() + '%'
        }
        difference_div.textContent = difference_text;

        // status name
        let status_div = diversity_progress_div.getElementsByClassName('status')[0];
        if (pc_dict['sa'] === 'below_benchmark') {
            status_div.textContent = 'below benchmark';
        }
        else if (pc_dict['sa'] === 'above_benchmark') {
            status_div.textContent = 'above benchmark';
        }
        else if (pc_dict['sa'] === 'at_benchmark') {
            status_div.textContent = 'at benchmark';
        }

        // bullet chart
        let plot_div = diversity_progress_div.getElementsByClassName('bullet-plot')[0];
        var traces = [];
        // const N = Object.keys(traceListBC[stageName]).length;
        // const yIntervalList = Array.from({length: N + 1}, (_, i) => i / N);
        // var intervalIndex = 0;
        let traces = structuredClone(pc_dict['bulletChartTrace']);
        var axisVisble = false
        let plot_traces = [];
        for (let trace of traces) {
            trace.gauge.axis.visible = axisVisble;
            plot_traces.push(trace);
        }
        //SC.style.height = `${N*128}px`;
        plot_div.style.height = "30px";
        Plotly.react(plot_div, plot_traces.flat(), layout, config);  // traces.flat()
    //     useResizeHandler: true,
    // style: {width: "100%", height: "100%"}

        // if diversity_class is low_diversity, then we want to add a list of biases in an accordion
        // fill the child element of 'accordion' with a list of biases
        // set interaction on click of the pc element above
        let all_biases = true;
        // if (diversity_class === 'low_diversity') {
        if (all_biases) {
            let accordion_div = diversity_progress_div.getElementsByClassName('bias-accordion')[0];

            // if no substantial biases found, give some alternative advice
            var biases_found = true;
            try {
                let num_biases = biases[pc_dict['pc']].length;
                if (num_biases === 0) {
                    biases_found = false;
                }
            }
            catch {
                biases_found = false;
            }
            if (biases_found === false) {
                let bias_row_sample = document.getElementById('bias-row')
                let bias_row = bias_row_sample.cloneNode(true)
                bias_row.setAttribute('id', '');
                bias_row.style.display = 'flex';

                // add text
                let extra_text_div = bias_row.getElementsByClassName('extra-text')[0];
                extra_text_div.style.display = 'block'
                if (diversity_class === 'low_diversity') {
                    extra_text_div.textContent = 'No substantial imbalances in the hiring process found; low diversity might be caused by a lack of diverse sourced applicants.';
                }
                else {
                    extra_text_div.textContent = 'No substantial imbalances in the hiring process found.';
                }

                // remove the bias div
                let bias_text = bias_row.getElementsByClassName('bias-strength')[0].parentElement;
                bias_text.style.display = 'none';

                // create link to the block page
                bias_row.addEventListener('click', function() {
                    document.location.href = "/block?dataSetID=" + homeDataSetID + '&pc=' + pc_dict['pc']; 
                });

                accordion_div.appendChild(bias_row);
            }

            // else, loop through biases
            else {

                // add disclaimer text for high diversity, that we have biases despite having high diversity
                if (diversity_class === 'high_diversity') {
                    let disclaimer_div = accordion_div.getElementsByClassName('disclaimer-row')[0];
                    disclaimer_div.style.display = 'block';
                    if (pc_dict['sa'] === 'at_benchmark') {
                        disclaimer_div.getElementsByClassName('extra-text')[0].textContent = 'Although diversity of hired candidates is at benchmark, you can still optimise your hiring decisions in the following areas:'
                    }
                    else {
                        disclaimer_div.getElementsByClassName('extra-text')[0].textContent = 'Although diversity of hired candidates is above benchmark, you can still optimise your hiring decisions in the following areas:'
                    }
                }
                var ii = 0;
                for (let bias_dict of biases[pc_dict['pc']]) {

                    // only show the top 3 biases
                    if (ii === 3) {
                        break
                    }

                    let bias_row_sample = document.getElementById('bias-row')
                    let bias_row = bias_row_sample.cloneNode(true)
                    bias_row.setAttribute('id', '');
                    bias_row.style.display = 'flex';

                    // add text
                    let bias_strength_span = bias_row.getElementsByClassName('bias-strength')[0];
                    bias_strength_span.textContent = bias_dict['EvidenceForBiasClass'];
                    let bias_group_span = bias_row.getElementsByClassName('bias-group')[0];
                    bias_group_span.textContent = bias_dict['neg_affected_group_name'];
                    let bias_group_span2 = bias_row.getElementsByClassName('bias-group')[1];
                    bias_group_span2.textContent = bias_dict['pos_affected_group_name'];
                    let bias_stage_span = bias_row.getElementsByClassName('bias-group')[2];
                    bias_stage_span.textContent = bias_dict['StepNiceName'];

                    // create link to the block page
                    bias_row.addEventListener('click', function() {
                        document.location.href = "/block?dataSetID=" + homeDataSetID + '&pc=' + pc_dict['pc'];
                    });

                    accordion_div.appendChild(bias_row);

                    ii = ii + 1;
                }
            }

            // add pointer to progress bar
            diversity_progress_div.classList.add("pointer");


            // interaction
            diversity_progress_div.addEventListener("click", function() {
                //this.classList.toggle("active");
                //var panel = this.nextElementSibling;
                if (accordion_div.style.maxHeight) {
                  accordion_div.style.maxHeight = null;
                } else {
                  accordion_div.style.maxHeight = accordion_div.scrollHeight + "px";
                }
            
            });

        }

        diversity_progress_collectiondiv.appendChild(diversity_progress_div);        
    }

}

function plotBulletChartAll(output_diversity_levels, homeDataSetID){

    // get Xmax on bullet chart
    let x_max = calculateDiversityXlim(output_diversity_levels);
    let biases = output_diversity_levels['all_biases'];

    const diversity_classes = ['low_diversity', 'high_diversity'];
    for (let diversity_class of diversity_classes) {
        let [traceDict, layout] = generateDiversityLevelsTraceLayout(output_diversity_levels[diversity_class], x_max);
        plotBulletChartForClass(traceDict, layout, diversity_class, biases, homeDataSetID);
    }
}


async function loadPage() {


    // dataSetId "1" is just a placeholder - the backend will grab the right ID
    setUserInitials(sessionStorage.getItem("UserInitials"));
    setWelcomeMessage(sessionStorage.getItem("OrganisationName"));

    var figures = await getFigureResult(sessionStorage.getItem("DomainId"), sessionStorage.getItem("IntegrationSettingsId"), "home", "1");
    // now grab the right ID because we need to to forward the user to the right block page
    const homeDataSetInfo = await getDataSetInfo(sessionStorage.getItem("DomainId"), sessionStorage.getItem("IntegrationSettingsId"), "home")
    //const homeDataSetID = homeDataSetInfo["homePageDataSetId"];
    const homeDataSetID = figures['datasetId'];

    var diversity_levels = JSON.parse(figures['low_high_diversity_levels'])['JobResults']['low_high_diversity_levels'];
    var population_barchart = JSON.parse(figures['population_benchmark_barchart'])['JobResults']['population_benchmark_barchart'];
    var global_counts = JSON.parse(figures['create_global_counts'])['JobResults']['create_global_counts'];
    const pcsInBlock = Object.keys(population_barchart['raw_numbers']);
    // set default pc, we just take the first occuring pc
    const homepage_pc = pcsInBlock[0];

    plotBulletChartAll(diversity_levels, homeDataSetID);
    let bar_plot_div = document.getElementById('industry-comparison-plot');
    plotCompositionBar(population_barchart, homepage_pc, bar_plot_div);

    //benchmark name
    const benchmark_name_span = document.getElementById('benchmark-name'); 
    benchmark_name_span.textContent = JSON.parse(figures['benchmarks'])[homepage_pc]["Name"];

    //total candidates 
    const stat1 = document.getElementById('stat-1');
    stat1.textContent = global_counts['TotalNumberOfCandidates'];

    //stages and cats with bias 
    const stat2 = document.getElementById('stat-2');
    stat2.textContent = global_counts['BiasSpread']['NumberOfStagesAndCatsWithBias'];

    //stages and cats below benchmark 
    const stat3 = document.getElementById('stat-3');
    stat3.textContent = global_counts['DiversityBenchmarking']['NumStagesAndCatsBelowThresh'];

    //evidence squares with substantial bias 
    const stat4 = document.getElementById('stat-4');
    try {
        const num_subst_bias = global_counts['EvidenceForBiasPairs']['NumberOfEvidenceSquaresWithSubstantialBias'];
        stat4.textContent = num_subst_bias;
    }
    catch {
        stat4.textContent = '0';
    }


    //////////////////// dropdowns and buttons //////////////////////
    // populate PC dropdown
    var dropDownPC = document.getElementById("dropdown-pc");
    for (let ii = 0; ii < pcsInBlock.length; ii++) {
        const pc = pcsInBlock[ii];
        let option = document.createElement("option");
        option.setAttribute('value', pc);
        option.setAttribute('id', pc);
        let optionText = document.createTextNode(pc);
        option.appendChild(optionText);
        dropDownPC.appendChild(option);
    }
    dropDownPC.addEventListener("change", function(event) {
        pc = event.target.value;
        benchmark_name_span.textContent = JSON.parse(figures['benchmarks'])[pc]["Name"];
        plotCompositionBar(population_barchart, pc, bar_plot_div);
    });
    dropDownPC.selectedIndex = 0;
}

async function main() {
    await initToken();
    await loadPage();
    await checkForUpdates(loadPage);
    stopLoader();
    setLogOutButton();
}

main();

